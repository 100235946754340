import { of } from 'rxjs';
import * as i0 from "@angular/core";
/** Service to get and set user preferences */
var UserPreferencesService = /** @class */ (function () {
    /** standard constructor for services */
    function UserPreferencesService() {
    }
    /**
     * Get user preference
     */
    UserPreferencesService.prototype.getPreference = function (key, persistent) {
        if (persistent) {
            return of(JSON.parse(localStorage.getItem(key)));
        }
        else {
            return of(JSON.parse(sessionStorage.getItem(key)));
        }
    };
    /**
     * Set user preference
     */
    UserPreferencesService.prototype.setPreference = function (userPreference) {
        if (userPreference.Persist) {
            localStorage.setItem(userPreference.Key, JSON.stringify(userPreference.Value));
        }
        else {
            sessionStorage.setItem(userPreference.Key, JSON.stringify(userPreference.Value));
        }
    };
    UserPreferencesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserPreferencesService_Factory() { return new UserPreferencesService(); }, token: UserPreferencesService, providedIn: "root" });
    return UserPreferencesService;
}());
export { UserPreferencesService };
