import { throwError, EMPTY } from 'rxjs';
import { RemoteLoggingService } from './remote-logging.service';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "./remote-logging.service";
import * as i2 from "@angular/router";
/**
 * HTTP response error handler.
 */
var HttpErrorHandlerService = /** @class */ (function () {
    /**
     * base constructor
     * @param logger remote logger service injector
     */
    function HttpErrorHandlerService(logger, router) {
        this.logger = logger;
        this.router = router;
    }
    /**
     * Handle Http response error.  redirecting to logout on occasion or just logging the error with the remote logging service.
     * @param actionName method/action from which the error occurred
     * @param returnError whether to return the error, required for redirectToLogout
     */
    HttpErrorHandlerService.prototype.handleHttpErrorResponse = function (actionName, returnError) {
        var _this = this;
        if (returnError === void 0) { returnError = true; }
        return function (error, caught) {
            var errorMsg = '';
            if (error.status === 401) {
                errorMsg = "While " + actionName + " user was noted as unauthenticated and logged out of the system. " + error.error.message;
                console.warn("While " + actionName + " user was noted as unauthenticated and logged out of the system", "" + error.error.message);
                _this.redirectToLogout();
            }
            else if (error.error && error.error.message) {
                // A client-side or network error occurred. Handle it accordingly.
                errorMsg = "An error occurred while " + actionName + ": " + error.error.message;
            }
            else {
                // The backend returned an unsuccessful response code.
                // The response body may contain clues as to what went wrong,
                errorMsg = "Backend returned code " + error.status + ", body was: " + JSON.stringify(error.error);
            }
            // return an observable with a user-facing error message
            var httpErr = new Error(errorMsg);
            _this.logger.logError(httpErr);
            if (returnError) {
                return throwError(httpErr);
            }
            else {
                return EMPTY;
            }
        };
    };
    /**
     * redirects the system to the logout page
     */
    HttpErrorHandlerService.prototype.redirectToLogout = function () {
        var _this = this;
        // TODO: maybe - change logout URL to something other than the COL logout view
        setTimeout(function () { return _this.router.navigate(['/logout'], { queryParams: { code: 4 } }); }, 50);
    };
    HttpErrorHandlerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HttpErrorHandlerService_Factory() { return new HttpErrorHandlerService(i0.ɵɵinject(i1.RemoteLoggingService), i0.ɵɵinject(i2.Router)); }, token: HttpErrorHandlerService, providedIn: "root" });
    return HttpErrorHandlerService;
}());
export { HttpErrorHandlerService };
