var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, OnDestroy, NgZone } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { Title } from "@angular/platform-browser";
import { SpringboardSetupService } from "src/app/core/services/springboard-setup.service";
import { BehaviorSubject } from "rxjs";
import { MatTableDataSource } from "@angular/material/table";
import { AuthenticationService } from "src/app/core/services/authentication.service";
import { ManageCookieLogoService } from 'src/app/core/services/manage-cookie-logo.service';
import { AppConfigService } from "src/app/core/services/app-config.service";
import { UserPreferencesService } from '../../../core/services/user-preferences.service';
import { Router } from "@angular/router";
import { CustomSnackbarComponent } from '../custom-snackbar/custom-snackbar.component';
import { SpringboardTimeoutService } from 'src/app/core/services/springboard-timeout.service';
import { SpringboardTimeoutComponent } from 'src/app/public/components/springboard/springboard-timeout/springboard-timeout.component';
import { MatSnackBar } from '@angular/material';
var SpringboardComponent = /** @class */ (function () {
    /**
     * Base constructor
     * @param spinner NgxSpinner
     * @param titleService: Title
     * @param springboardSetupSvc: SpringboardSetupService
     */
    function SpringboardComponent(spinner, titleService, springboardSetupService, authSvc, router, snackBar, cookieLogoService, appConfig, springboardTimeoutService, userPreferencesService, _ngZone, _snackBar) {
        this.spinner = spinner;
        this.titleService = titleService;
        this.springboardSetupService = springboardSetupService;
        this.authSvc = authSvc;
        this.router = router;
        this.snackBar = snackBar;
        this.cookieLogoService = cookieLogoService;
        this.appConfig = appConfig;
        this.springboardTimeoutService = springboardTimeoutService;
        this.userPreferencesService = userPreferencesService;
        this._ngZone = _ngZone;
        this._snackBar = _snackBar;
        /** referred */
        this.referred = false;
        /** referrer authorized */
        this.referrerAuthorized = false;
        /** show referrer error */
        this.referrerError = false;
        /** referrerMessage variable to store the current referrer message */
        this.referrerMessage = "You have not been granted access to $appName.";
        /** referrerMessage subject to emit changes to referrerMessage */
        this.referrerMessage$ = new BehaviorSubject(this.referrerMessage);
        /** url regex */
        this.urlRegex = /(\/#|\/|#)$/;
        /** show context selection */
        this.displayContextSelection = false;
        /** show context selection menu option */
        this.displayContextOption = true;
        /** show error */
        this.springboardError = false;
        /** show logged out */
        this.displayLoggedOut = false;
        /** is context selected */
        this.contextSelected = false;
        /** select account chosen in user-context component menu */
        this.contextChange = false;
        /** displayedColumns variable to store the displayed columns array */
        this.displayedColumns = ["company", "companyAddr"];
        /** show app selection */
        this.displayAppSelection = true;
        /** selectedApp variable to store the selected app */
        this.selectedApp = "";
        /** errorHeadings object to store error headings */
        this.errorHeadings = {
            GENRAL_ERROR: "Please contact the Cartus Help Desk",
            APP_NOT_AVAILABLE: "$appName is currently not available",
            APP_NOT_AVAILABLE_DEFAULT: "The selected application is currently not available",
            NO_APPS_ASSIGNED: "You currently have no applications assigned",
            LOGIN_FAILED: "The logon attempt failed"
        };
        /** errorHeaders variable to store the current error header */
        this.errorHeader = this.errorHeadings.GENRAL_ERROR;
        /** snackbar horizontal position */
        this.horizontalPosition = 'center';
        /** snackbar vertical position */
        this.verticalPosition = 'bottom';
        /** sbErrorConfig object to hold snackBar config for errors */
        this.sbErrorConfig = {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            data: '',
            duration: 10000
        };
        /** sbTimeoutConfig object to hold snackBar config for timeout */
        this.sbTimeoutConfig = {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
        };
        /** logOutMessages object to store logged out messages */
        this.logOutMessages = {
            NORMAL: "You have been successfully logged out",
            TIMEOUT: "You have been logged out due to inactivity"
        };
        /** logOutMessage variable to store the current log out message */
        this.logOutMessage = this.logOutMessages.NORMAL;
        /** idle timeout minutes */
        this.idleTimeoutMinutes = 15;
        /** idle timeout cookie name */
        this.tokenCookieName = 'car-ses-tok';
        /** idle timeout cookie name */
        this.idleCookieName = 'lastAction';
        /** idle timeout keep-alive interval */
        this.pingInterval = 0;
        /** idle timeout poll interval */
        this.pollInterval = 1000;
        /** idle timeout check time */
        this.idleCheckSeconds = 60;
        /** idle timeout last mouse X */
        this.lastX = 0;
        /** idle timeout last mouse Y */
        this.lastY = 0;
        /** idle timeout warning */
        this.isIdleTimeoutWarning = false;
        /** idle timeout warning minutes */
        this.idleTimeoutWarningMinutes = 13;
        /** session token refresh timeout minutes */
        this.refreshTokenTimeout = 9; // 3 chances to update in a 30 minute window
        /** app-context cookie name  */
        this.appContextCookieName = 'app-context';
    }
    SpringboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.spinner.show();
        this.titleService.setTitle("Springboard");
        this.springboardTimeoutService.timeoutData$.pipe().subscribe(function (response) {
            if (response) {
                _this._ngZone.run(function () {
                    _this._snackBar.openFromComponent(SpringboardTimeoutComponent, _this.sbTimeoutConfig);
                });
            }
            else {
                if (_this.isIdleTimeoutWarning === true) {
                    _this._snackBar.dismiss();
                }
            }
        });
        this.refreshSessionInApp();
        this.startIdleTimeCountDown();
        this.authSvc.trySso().then(function (tokenOrTokens) {
            if (tokenOrTokens && tokenOrTokens.tokens) {
                console.log("SSO authenticated");
                _this.cookieLogoService.setSessionCookie(tokenOrTokens.tokens.accessToken);
                _this.accessToken = tokenOrTokens.tokens.accessToken.accessToken;
                _this.getUserContexts();
            }
            else {
                console.log("SSO failed");
                _this.accessToken = _this.cookieLogoService.getCookie('car-ses-tok');
                if (_this.accessToken) {
                    console.log("Token retrieved from service");
                    _this.getUserContexts();
                }
                else {
                    console.log("No okta token stored");
                    _this.errorHeader = _this.errorHeadings.LOGIN_FAILED;
                    _this.springboardError = true;
                    _this.spinner.hide();
                }
            }
        });
    };
    SpringboardComponent.prototype.ngOnDestroy = function () {
        if (this.prefSub) {
            this.prefSub.unsubscribe();
        }
    };
    /** getUserContexts method to get all party and organization details associated with the partyId contained in the access token */
    SpringboardComponent.prototype.getUserContexts = function () {
        var _this = this;
        this.springboardSetupService.getContexts(this.accessToken).toPromise()
            .then(function (partyAndOrganizationDetails) { return __awaiter(_this, void 0, void 0, function () {
            var partiesRoles_1, now_1, userContext_1, partiesCount, transfereeCount, legacyTransfereeCount, clientContactCount, transferee_1, legacyTransferee_1, clientContact_1;
            var _this = this;
            return __generator(this, function (_a) {
                if (!partyAndOrganizationDetails || partyAndOrganizationDetails.length === 0) {
                    this.errorHeader = this.errorHeadings.GENRAL_ERROR;
                    this.springboardError = true;
                    this.spinner.hide();
                }
                else {
                    partiesRoles_1 = [{}];
                    now_1 = new Date();
                    userContext_1 = { count: 0, contexts: [] };
                    partyAndOrganizationDetails.forEach(function (item, index) {
                        if (item.party) {
                            var contexts = {};
                            contexts.partyId = item.party._id;
                            contexts.firstName = item.party.currentName && item.party.currentName.names && item.party.currentName.names.length > 0
                                ? item.party.currentName.names[0] : null;
                            contexts.lastName = item.party.currentName && item.party.currentName.names && item.party.currentName.names.length > 1
                                ? item.party.currentName.names[item.party.currentName.names.length - 1] : null;
                            contexts.multiCurrencyFlag = false; //TODO: not in currently in payload, hardcoding to false as per discussion
                            if (item.organizationDetails) {
                                if (item.organizationDetails.entityName) {
                                    contexts.clientNo = item.organizationDetails.clientNumber;
                                    contexts.clientLegalName = item.organizationDetails.entityName;
                                }
                                if (item.organizationDetails.contactMechanisms && item.organizationDetails.contactMechanisms.length > 0) {
                                    var address = item.organizationDetails.contactMechanisms.filter(function (contactMechanism) {
                                        return contactMechanism.contactType === "address" &&
                                            contactMechanism.usageType === 'business';
                                    });
                                    if (address.length > 0) {
                                        contexts.clientAddrLine1 = address[0].addressLines[0] ? address[0].addressLines[0] : null;
                                        contexts.clientAddrLine2 = address[0].addressLines[1] ? address[0].addressLines[1] : null;
                                        contexts.clientCityName = address[0].city ? address[0].city : null;
                                        contexts.clientStProvName = address[0].state ? address[0].state : null;
                                        contexts.clientCountryName = address[0].country ? address[0].country : null;
                                        contexts.clientPostalCode = address[0].postalCode ? address[0].postalCode : null;
                                    }
                                }
                            }
                            partiesRoles_1[index] = { partyId: item.party._id, roles: [] };
                            if (item.party.roles) {
                                item.party.roles.forEach(function (role) {
                                    if (!role.name) {
                                        _this.springboardError = true;
                                        _this.errorHeader = _this.errorHeadings.GENRAL_ERROR;
                                        _this.spinner.hide();
                                    }
                                    if ((!role.fromDate || (role.fromDate && now_1 > new Date(role.fromDate))) && (!role.toDate || (role.toDate && now_1 < new Date(role.toDate)))) {
                                        partiesRoles_1[index].roles.push(role.name);
                                    }
                                });
                                if (_this.springboardError)
                                    return;
                            }
                            userContext_1.contexts.push(contexts);
                        }
                    });
                    userContext_1.count = userContext_1.contexts.length;
                    this.contextsList = userContext_1;
                    if (this.contextsList.count === 0) {
                        this.errorHeader = this.errorHeadings.GENRAL_ERROR;
                        this.springboardError = true;
                        this.spinner.hide();
                        return [2 /*return*/];
                    }
                    if (this.contextsList.count === 1) {
                        this.selectContext(this.contextsList.contexts[0]);
                        this.displayContextOption = false;
                    }
                    else {
                        partiesCount = partiesRoles_1.length;
                        transfereeCount = partiesRoles_1.filter(function (party) { return party.roles.includes('transferee'); }).length;
                        legacyTransfereeCount = partiesRoles_1.filter(function (party) { return party.roles.includes('legacy-transferee'); }).length;
                        clientContactCount = partiesRoles_1.filter(function (party) { return party.roles.includes('client-contact'); }).length;
                        if (transfereeCount > 0) {
                            transferee_1 = partiesRoles_1.find(function (party) { return party.roles.includes('transferee'); });
                            this.selectContext(this.contextsList.contexts.find(function (context) { return context.partyId === transferee_1.partyId; }));
                            this.displayContextOption = false;
                        }
                        else if (clientContactCount === partiesCount && legacyTransfereeCount === 0) {
                            this.dataSource = new MatTableDataSource(this.contextsList.contexts);
                            this.displayContextSelection = true;
                            this.spinner.hide();
                        }
                        else if (legacyTransfereeCount === partiesCount && clientContactCount === 0) {
                            legacyTransferee_1 = partiesRoles_1.find(function (party) { return party.roles.includes('legacy-transferee'); });
                            this.selectContext(this.contextsList.contexts.find(function (context) { return context.partyId === legacyTransferee_1.partyId; }));
                            this.displayContextOption = false;
                        }
                        else if (legacyTransfereeCount > 0 && clientContactCount > 0) {
                            clientContact_1 = partiesRoles_1.find(function (party) { return party.roles.includes('client-contact'); });
                            this.selectContext(this.contextsList.contexts.find(function (context) { return context.partyId === clientContact_1.partyId; }));
                            this.displayContextOption = false;
                        }
                        else {
                            this.dataSource = new MatTableDataSource(this.contextsList.contexts);
                            this.displayContextSelection = true;
                            this.spinner.hide();
                        }
                    }
                }
                return [2 /*return*/];
            });
        }); });
    };
    /** sortContexts method to sort the context selector table */
    SpringboardComponent.prototype.sortContexts = function (sort) {
        var _this = this;
        if (!sort.active || sort.direction === "")
            return;
        var data = this.contextsList.contexts.slice();
        var sortedData = data.slice().sort(function (a, b) {
            var isAsc = sort.direction === "asc";
            switch (sort.active) {
                case "clientLegalName":
                    return _this.compare(a.clientLegalName, b.clientLegalName, isAsc);
                case "clientAddrLine1":
                    return _this.compare(a.clientAddrLine1, b.clientAddrLine1, isAsc);
                default:
                    return 0;
            }
        });
        this.contextsList.contexts = sortedData;
        this.dataSource = new MatTableDataSource(sortedData);
    };
    /** compare method used to compare values when sorting the context selector table */
    SpringboardComponent.prototype.compare = function (a, b, isAsc) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    };
    /** selectContext method to set the context chosen in the context selector */
    SpringboardComponent.prototype.selectContext = function (context) {
        this.cookieLogoService.setCookie(this.appContextCookieName, context.partyId, 1, '/', '.cartus.com', true);
        sessionStorage.setItem("car-ses-con", context.partyId);
        sessionStorage.setItem("UserContext", JSON.stringify(context));
        this.partyId = context.partyId;
        this.selectedContext = context;
        this.displayContextSelection = false;
        this.getApps(this.partyId);
    };
    /** getApps method to get the apps applicable to the selected context */
    SpringboardComponent.prototype.getApps = function (partyId) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        return [4 /*yield*/, this.springboardSetupService
                                .getApps(this.accessToken, partyId)
                                .toPromise()
                                .then(function (springboardApps) { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.findReferrer(springboardApps)];
                                        case 1:
                                            _a.sent();
                                            if (springboardApps && springboardApps.Applications && springboardApps.Applications.length > 0) {
                                                if (this.referred) {
                                                    if (this.referrerAuthorized) {
                                                        this.applications = springboardApps.Applications;
                                                        this.displayAppSelection = false;
                                                        this.appSelectedHandler(this.referrerAppName);
                                                    }
                                                    else {
                                                        this.referrerError = true;
                                                        this.applications = springboardApps.Applications;
                                                        this.displayAppSelection = true;
                                                    }
                                                }
                                                else {
                                                    if (springboardApps.Applications.length === 1) {
                                                        this.applications = springboardApps.Applications;
                                                        this.displayAppSelection = false;
                                                        this.appSelectedHandler(springboardApps.Applications[0].name);
                                                    }
                                                    else {
                                                        this.applications = springboardApps.Applications;
                                                        this.displayAppSelection = true;
                                                    }
                                                }
                                            }
                                            else {
                                                this.applications = null;
                                                this.errorHeader = this.errorHeadings.NO_APPS_ASSIGNED;
                                                this.springboardError = true;
                                            }
                                            return [2 /*return*/];
                                    }
                                });
                            }); })];
                    case 1:
                        _a.sent();
                        this.spinner.hide();
                        return [2 /*return*/];
                }
            });
        });
    };
    //** handle the value emitted from child user-context component when 'select account' is chosen */
    SpringboardComponent.prototype.contextChangeHandler = function (valueEmitted) {
        if (valueEmitted) {
            this.applications = null;
            this.cookieLogoService.removeCookies([this.appContextCookieName]);
            sessionStorage.removeItem("car-ses-con");
            sessionStorage.removeItem("UserContext");
            this.selectedContext = null;
            this.displayContextSelection = true;
            this.contextSelected = false;
            this.springboardError = false;
            this.referrerError = false;
        }
    };
    //** handle the value emitted from child springboard-app when an app is selected and redirect if valid */
    SpringboardComponent.prototype.appSelectedHandler = function (valueEmitted) {
        return __awaiter(this, void 0, void 0, function () {
            var appName, coError, url;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spinner.show();
                        appName = this.applications.filter(function (app) { return app.name === valueEmitted; });
                        coError = false;
                        if (!(valueEmitted === "cartusOnline")) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.springboardSetupService.getColToken(this.accessToken, this.partyId).toPromise()
                                .then(function (resp) { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    if (!resp || (resp && resp.status !== 200)) {
                                        this.spinner.hide();
                                        coError = true;
                                        this.sbErrorConfig.data = appName.length ?
                                            this.errorHeadings.APP_NOT_AVAILABLE.replace('$appName', appName[0].displayName) :
                                            this.errorHeadings.APP_NOT_AVAILABLE_DEFAULT;
                                        this.snackBar.openFromComponent(CustomSnackbarComponent, this.sbErrorConfig);
                                        // is cartusOnline the only app available to the user?
                                        if (this.applications.length === 1) {
                                            // display the app selector because the token call failed
                                            this.displayAppSelection = true;
                                        }
                                    }
                                    return [2 /*return*/];
                                });
                            }); })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (!coError) {
                            url = this.springboardSetupService.getAppUrlFromName(valueEmitted);
                            if (url && url.indexOf("http") > -1) {
                                this.titleService.setTitle("Loading...");
                                this.router.navigate(["/externalRedirect", { externalUrl: url }], {
                                    skipLocationChange: true,
                                });
                            }
                            else {
                                this.sbErrorConfig.data = appName.length ?
                                    this.errorHeadings.APP_NOT_AVAILABLE.replace('$appName', appName[0].displayName) :
                                    this.errorHeadings.APP_NOT_AVAILABLE_DEFAULT;
                                this.snackBar.openFromComponent(CustomSnackbarComponent, this.sbErrorConfig);
                            }
                            this.spinner.hide();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    //** handle the value emitted from child user-context when logout is selected */
    SpringboardComponent.prototype.logoutHandler = function (valueEmitted) {
        if (valueEmitted) {
            this.authSvc.signOut();
            this.onKeyPress = function () { };
            this.onMouseMove = function () { };
            clearInterval(this.refreshTokenInterval);
            if (this.lastTimeOut)
                clearTimeout(this.lastTimeOut);
            if (this.isIdleTimeoutWarning)
                this._snackBar.dismiss();
            sessionStorage.clear();
            this.cookieLogoService.removeCookies([this.tokenCookieName, this.idleCookieName]);
            this.displayContextSelection = false;
            this.applications = null;
            this.contextsList = null;
            this.selectedContext = null;
            this.springboardError = false;
            this.referrerError = false;
            this.displayLoggedOut = true;
        }
    };
    /** start refresh session interval */
    SpringboardComponent.prototype.refreshSessionInApp = function () {
        var _this = this;
        this.refreshTokenInterval = setInterval(function () {
            _this.refreshToken();
        }, 60000 * this.refreshTokenTimeout);
    };
    /** refresh token */
    SpringboardComponent.prototype.refreshToken = function () {
        var _this = this;
        if (!this.springboardError) {
            this.authSvc.refreshSession().then(function (freshToken) {
                if (freshToken)
                    _this.cookieLogoService.setSessionCookie(freshToken);
            }).catch(function (err) {
                console.log('error in freshToken :', err);
            });
        }
    };
    /** start idle timeout */
    SpringboardComponent.prototype.startIdleTimeCountDown = function () {
        var _this = this;
        if (this.lastTimeOut)
            clearTimeout(this.lastTimeOut);
        this.refreshIdleCookie();
        this._ngZone.runOutsideAngular(function () {
            _this.lastTimeOut = setTimeout(_this.checkIdle.bind(_this), _this.pollInterval);
        });
    };
    /** refresh idle cookie */
    SpringboardComponent.prototype.refreshIdleCookie = function () {
        this.springboardTimeoutService.timeoutData.next(false);
        var currentTime = new Date().getTime();
        var idleExpireMs = currentTime + (this.idleTimeoutMinutes * 60000) - 500;
        this.idleTimeoutWarningInitiateMinutes = currentTime + (this.idleTimeoutWarningMinutes * 60000) - 500;
        this.isIdleTimeoutWarning = false;
        this.cookieLogoService.setCookie(this.idleCookieName, idleExpireMs.toString(), 1, '/', '.cartus.com', true);
    };
    /** check idle timeout status */
    SpringboardComponent.prototype.checkIdle = function () {
        var _this = this;
        var idleExpireMs = parseInt(this.cookieLogoService.getCookie(this.idleCookieName), 10);
        var currTimeMs = new Date().getTime();
        if (!this.springboardError && currTimeMs > idleExpireMs) {
            this.logOutMessage = this.logOutMessages.TIMEOUT;
            this.logoutHandler(true);
        }
        else {
            if (!this.springboardError && !this.isIdleTimeoutWarning && currTimeMs >= this.idleTimeoutWarningInitiateMinutes) {
                this.isIdleTimeoutWarning = true;
                this.springboardTimeoutService.timeoutData.next(true);
            }
            this.pingInterval += this.pollInterval;
            if (this.pingInterval === 1000 * this.idleCheckSeconds)
                this.pingInterval = 0;
            this._ngZone.runOutsideAngular(function () {
                _this.lastTimeOut = setTimeout(_this.checkIdle.bind(_this), _this.pollInterval);
            });
        }
    };
    /** listen for mouse events */
    SpringboardComponent.prototype.onMouseMove = function (e) {
        if (e.pageX !== this.lastX || e.pageY !== this.lastY) {
            this.lastX = e.pageX;
            this.lastY = e.pageY;
            this.refreshIdleCookie();
        }
    };
    /** listen for keypress events */
    SpringboardComponent.prototype.onKeyPress = function () {
        this.refreshIdleCookie();
    };
    /** establish if an application referred to login */
    SpringboardComponent.prototype.findReferrer = function (springboardApps) {
        return __awaiter(this, void 0, void 0, function () {
            var applicationList, referredURL, _i, _a, app, URL_1, strippedUrl_1, determinedApp, friendlyAppName;
            return __generator(this, function (_b) {
                applicationList = [
                    {
                        name: 'movepro360',
                        friendlyName: 'MovePro360'
                    },
                    {
                        name: 'mobilifyhr',
                        friendlyName: 'Mobilify HR',
                    },
                    {
                        name: 'supplierportal',
                        friendlyName: 'Supplier Portal'
                    },
                    {
                        name: 'benefitsbuilder',
                        friendlyName: 'Benefits Builder'
                    },
                    {
                        name: 'compensationServices',
                        friendlyName: 'Compensation Services'
                    }
                ];
                this.prefSub = this.userPreferencesService.getPreference('referrer', false).subscribe(function (referrer) {
                    referredURL = referrer ? referrer : undefined;
                });
                if (referredURL) {
                    this.referred = true;
                    if (springboardApps && springboardApps.Applications) {
                        for (_i = 0, _a = springboardApps.Applications; _i < _a.length; _i++) {
                            app = _a[_i];
                            if (!this.referrerAppName && app.name) {
                                URL_1 = this.springboardSetupService.getAppUrlFromName(app.name);
                                if (URL_1.replace(this.urlRegex, '').toLowerCase() === referredURL.replace(this.urlRegex, '').toLowerCase()) {
                                    this.referrerAppName = app.name;
                                    this.referrerAuthorized = true;
                                }
                            }
                        }
                        if (!this.referrerAuthorized) {
                            strippedUrl_1 = referredURL.replace(/^https?:\/\//, '').replace(/\..*$/, '');
                            determinedApp = applicationList.filter(function (loc) { return strippedUrl_1.includes(loc.name); });
                            friendlyAppName = determinedApp.length > 0 ? determinedApp[0].friendlyName : referredURL;
                            this.referrerMessage$.next(this.referrerMessage.replace('$appName', friendlyAppName));
                        }
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    return SpringboardComponent;
}());
export { SpringboardComponent };
