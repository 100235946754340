import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiConfigService } from './api-config.service';
import * as i0 from "@angular/core";
import * as i1 from "./api-config.service";
import * as i2 from "@angular/common/http";
/**
 * Custom parameter codec to correctly handle the plus sign in parameter
 * values. See https://github.com/angular/angular/issues/18261
 */
var ParameterCodec = /** @class */ (function () {
    function ParameterCodec() {
    }
    /** URL encode a key */
    ParameterCodec.prototype.encodeKey = function (key) {
        return encodeURIComponent(key);
    };
    /** URL encode a value */
    ParameterCodec.prototype.encodeValue = function (value) {
        return encodeURIComponent(value);
    };
    /** URL decode a key */
    ParameterCodec.prototype.decodeKey = function (key) {
        return decodeURIComponent(key);
    };
    /** URL decode a value */
    ParameterCodec.prototype.decodeValue = function (value) {
        return decodeURIComponent(value);
    };
    return ParameterCodec;
}());
/** @ignore */
var PARAMETER_CODEC = new ParameterCodec();
/**
 * Gets config data for API services
 */
var BaseService = /** @class */ (function () {
    /**
     * base constructor
     * @param config API Config Service injection
     * @param http HTTP Client injection
     */
    function BaseService(config, http) {
        this.config = config;
        this.http = http;
    }
    Object.defineProperty(BaseService.prototype, "rootUrl", {
        /**
         * Returns the root url for API operations. If not set directly in this
         * service, will fallback to ApiConfiguration.rootUrl.
         */
        get: function () {
            return this._rootUrl || this.config.rootUrl;
        },
        /**
         * Sets the root URL for API operations in this service.
         */
        set: function (rootUrl) {
            this._rootUrl = rootUrl;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Creates a new `HttpParams` with the correct codec
     */
    BaseService.prototype.newParams = function () {
        return new HttpParams({
            encoder: PARAMETER_CODEC
        });
    };
    BaseService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BaseService_Factory() { return new BaseService(i0.ɵɵinject(i1.ApiConfigService), i0.ɵɵinject(i2.HttpClient)); }, token: BaseService, providedIn: "root" });
    return BaseService;
}());
export { BaseService };
