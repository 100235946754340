import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfigService } from "./app-config.service";
import { BaseClientService } from "./base-client.service";
import { RemoteLoggingService } from "./remote-logging.service";
import * as i0 from "@angular/core";
import * as i1 from "./app-config.service";
import * as i2 from "./base-client.service";
import * as i3 from "./remote-logging.service";
var SpringboardSetupService = /** @class */ (function () {
    function SpringboardSetupService(appConfig, baseClient, logSvc) {
        this.appConfig = appConfig;
        this.baseClient = baseClient;
        this.logSvc = logSvc;
    }
    /**
     * returns an array of PartyAndOrganizationDetails
     */
    SpringboardSetupService.prototype.getContexts = function (token) {
        var _this = this;
        var headers = new HttpHeaders({ 'authorization': token });
        var params = new HttpParams();
        return this.baseClient.getWithOptions("/user/appcontext", "get the springboard contexts list", params, headers).pipe(map(function (r) { return r.body; }), catchError(function (err, source) {
            var empty = null;
            _this.logSvc.logError(err);
            return of(empty);
        }));
    };
    /**
     * returns the applications available to the app-context
     */
    SpringboardSetupService.prototype.getApps = function (token, partyId) {
        var _this = this;
        var headers = new HttpHeaders({ 'authorization': token, 'app-context': partyId });
        return this.baseClient.getWithOptions("/springboard", "get the springboard apps list", null, headers)
            .pipe(map(function (r) { return r.body; }), catchError(function (err, source) {
            var empty = null;
            _this.logSvc.logError(err);
            return of(empty);
        }));
    };
    /**
     * sets a token for CartusOnline authentication
     */
    SpringboardSetupService.prototype.getColToken = function (token, partyId) {
        var intUrl = this.appConfig.getConfig("integrationapi");
        var headers = new HttpHeaders({ 'authorization': token, 'app-context': partyId });
        return this.baseClient.getExt(intUrl + 'v1/navigateToCOL', 'get the CartusOnline token', null, headers, true).pipe(map(function (r) { return r; }), catchError(function (err, source) {
            var empty = null;
            return of(empty);
        }));
    };
    /**
     * retrieve app url from config
     */
    SpringboardSetupService.prototype.getAppUrlFromName = function (appName) {
        var url = this.appConfig.getConfig(appName);
        return url;
    };
    SpringboardSetupService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SpringboardSetupService_Factory() { return new SpringboardSetupService(i0.ɵɵinject(i1.AppConfigService), i0.ɵɵinject(i2.BaseClientService), i0.ɵɵinject(i3.RemoteLoggingService)); }, token: SpringboardSetupService, providedIn: "root" });
    return SpringboardSetupService;
}());
export { SpringboardSetupService };
