import { of } from 'rxjs';
import { BaseClientService } from 'src/app/core/services/base-client.service';
import { catchError, map } from 'rxjs/operators';
import { RemoteLoggingService } from 'src/app/core/services/remote-logging.service';
import * as i0 from "@angular/core";
import * as i1 from "./base-client.service";
import * as i2 from "./remote-logging.service";
/** user service to post details of login to the server
 * header
 */
var SelfRegistrationValidationService = /** @class */ (function () {
    /**
     * base constructor
     */
    function SelfRegistrationValidationService(baseClient, logSvc) {
        this.baseClient = baseClient;
        this.logSvc = logSvc;
    }
    /**
    * Handle sending of OTP
    * @param user user to send the OTP code to
    * @param sendPhone whether the OTP code should be sent to the email (instead of phone)
    * @param catchError error from API call
    */
    SelfRegistrationValidationService.prototype.sendOTP = function (user, sendPhone) {
        var _this = this;
        var path = '/otp/generatetotp';
        return this.baseClient.post(path, user).pipe(map(function (r) { return r.body; }), catchError(function (err, source) {
            var empty = null;
            _this.logSvc.logError(err);
            return of(empty);
        }));
    };
    /**
    * Handle validation of OTP
    * @param user useriD of the user to verify and OTP code entered to verify
    * @param sendPhone whether the OTP code should be sent to the email (instead of phone)
    * @param catchError error from API call
    */
    SelfRegistrationValidationService.prototype.validateOTP = function (user, sendPhone) {
        var _this = this;
        var path = '/otp/validatetotp';
        return this.baseClient.post(path, user).pipe(map(function (r) { return r.body; }), catchError(function (err, source) {
            var empty = null;
            _this.logSvc.logError(err);
            return of(empty);
        }));
    };
    SelfRegistrationValidationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SelfRegistrationValidationService_Factory() { return new SelfRegistrationValidationService(i0.ɵɵinject(i1.BaseClientService), i0.ɵɵinject(i2.RemoteLoggingService)); }, token: SelfRegistrationValidationService, providedIn: "root" });
    return SelfRegistrationValidationService;
}());
export { SelfRegistrationValidationService };
