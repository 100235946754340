import * as i0 from "@angular/core";
/**
 * Gets the global application configuration data
 */
var AppConfigService = /** @class */ (function () {
    /**
     * Initialize application config data
     */
    function AppConfigService(config) {
        this.config = config;
        var configStr = JSON.stringify(config, null, 2);
        this.configData = JSON.parse(configStr);
    }
    /**
     * Use for in-string replacement of search with replacement in str
     */
    AppConfigService.prototype.replaceString = function (str, search, replacement) {
        return str.replace(new RegExp(search, 'g'), replacement);
    };
    /**
     * Use to get the data found in the second file (config file)
     */
    AppConfigService.prototype.getConfig = function (key) {
        return this.configData[key];
    };
    /**
     * Use to get the environment name
     */
    AppConfigService.prototype.getEnv = function () {
        return this.configData.environment || 'dev';
    };
    AppConfigService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppConfigService_Factory() { return new AppConfigService(i0.ɵɵinject("appConfig")); }, token: AppConfigService, providedIn: "root" });
    return AppConfigService;
}());
export { AppConfigService };
