var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, OnDestroy, NgZone } from '@angular/core';
import { Validators, FormBuilder, FormControl } from '@angular/forms';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ManageUserSharedService } from '../../../core/services/manage-user-shared.service';
import { TextEncoder } from 'text-encoding'; // Added for Edge browser compatiblity
import { UserPreferencesService } from '../../../core/services/user-preferences.service';
import { ManageCookieLogoService } from 'src/app/core/services/manage-cookie-logo.service';
import { Subscription } from 'rxjs';
import * as OktaSignIn from '@okta/okta-signin-widget';
import { Title } from '@angular/platform-browser';
import { FeatureFlagService } from 'src/app/core/services/feature-flag.service';
/** Base Login component */
var LoginComponent = /** @class */ (function () {
    function LoginComponent(authSvc, spinner, fb, router, appConfig, moveSharedService, userPreferencesService, route, titleService, cookieLogoSvc, featureFlagService, _ngZone) {
        this.authSvc = authSvc;
        this.spinner = spinner;
        this.fb = fb;
        this.router = router;
        this.appConfig = appConfig;
        this.moveSharedService = moveSharedService;
        this.userPreferencesService = userPreferencesService;
        this.route = route;
        this.titleService = titleService;
        this.cookieLogoSvc = cookieLogoSvc;
        this.featureFlagService = featureFlagService;
        this._ngZone = _ngZone;
        /** Subscription property for subscribing and unsubscribing services */
        this.subscription = new Subscription();
        /*Available Sites*/
        this.authorizedLocations = ['movePro360', 'mobilifyHR', 'mobilifyUI', 'supplierPortal', 'benefitsBuilder', 'compensationServices', 'domain'];
        /*To check redirected from authorised site or not*/
        this.authorisedSite = false;
        /*For the redirected URL */
        this.referredURL = '';
        /* Request Email Button Status*/
        this.requestEmailStatus = false;
        /* Okta session timeout */
        this.idleTimeoutMinutes = 55;
        /*Inactive user flag*/
        this.inactiveUser = false;
        /*Edge Chromium flag*/
        this.isEdgeChromium = false;
        /* Okta widget config */
        this.signInWidgetConfig = {};
        /**
       * Initializes the value
       * @param router Instance Router
       */
        /**Base Constructor for Login Component
         * @param authSvc Authentication Service to authenticate the user details
         */
        /** Login Form.  Contains email and password fields, which are both required. */
        this.loginForm = this.fb.group({
            email: new FormControl('', Validators.compose([
                Validators.required,
                Validators.pattern('^[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+(?:\\.[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])$')
            ])),
            password: ['', [Validators.required]]
        });
        this.templateString = {
            BNNER_TXT: "Technology That Moves You",
            MOVEPRO360_TXT: "Welcome to MovePro360 \u2500 your centralized mobility hub and single source of truth " +
                "for all your relocation and global talent mobility goals. Optimize move outcomes, anticipate " +
                "needs, access predictive insights, and much more."
        };
        /** Used to determine if the Google sign in is displayed */
        this.showGoogleButton = false;
        /** Used to hold the help links to display when the Google sign in is displayed */
        this.googleHelpLinks = ['Help'];
        /** Added for Edge Browser compatiblity */
        if (!window['TextEncoder']) {
            window['TextEncoder'] = TextEncoder;
        }
    }
    /** Login Component's Init Function */
    LoginComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var oktaUrl, oktaId, oktaRedirectUrl, oktaIdpRedirectUrl, oktaGoolgleIdpId, googleIDPFeatureFlag, routeData;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // Deleting cookies on ngOnInit
                        this.cookieLogoSvc.removeAllCookies();
                        this.titleService.setTitle('Login');
                        oktaUrl = String(this.appConfig.getConfig('oktaUrl'));
                        oktaId = String(this.appConfig.getConfig('oktaClientId'));
                        oktaRedirectUrl = String(this.appConfig.getConfig('oktaRedirectUri'));
                        oktaIdpRedirectUrl = String(this.appConfig.getConfig('oktaIdpRedirectUrl'));
                        oktaGoolgleIdpId = String(this.appConfig.getConfig('oktaGoolgleIdpId'));
                        /* End Region */
                        this.spinner.show();
                        return [4 /*yield*/, this.getGoogleIDPFeatureFlag()];
                    case 1:
                        googleIDPFeatureFlag = _a.sent();
                        this.routeSub = this.route.data.subscribe(function (v) { return routeData = v; });
                        (routeData['googleIdp']) && googleIDPFeatureFlag ? this.showGoogleButton = true : this.showGoogleButton = false;
                        this.isEdgeChromium = this.detectEdgeChromium();
                        // Initialize Okta widget
                        this.signInWidgetConfig = {
                            language: 'en',
                            i18n: {
                                'en': {
                                    'primaryauth.title': 'Enter your credentials to log in',
                                    'primaryauth.submit': 'Log In'
                                }
                            },
                            features: {
                                idpDisovery: true,
                                rememberMe: false,
                                selfServiceUnlock: true,
                                multiOptionalFactorEnroll: true
                            },
                            baseUrl: oktaUrl.split('/oauth2')[0],
                            clientId: oktaId,
                            redirectUri: this.showGoogleButton ? oktaIdpRedirectUrl : oktaRedirectUrl,
                            authParams: {
                                issuer: oktaUrl,
                                responseType: ['id_token', 'token'],
                                scopes: ['openid', 'email', 'profile']
                            },
                            helpLinks: {
                                help: '/#/contactUs',
                                forgotPassword: this.showGoogleButton ? '/#/contactUs' : null,
                                unlock: this.showGoogleButton ? '/#/contactUs' : null,
                            },
                            idps: this.showGoogleButton ? [
                                { type: 'GOOGLE', id: oktaGoolgleIdpId }
                            ] : null
                        };
                        this.signInWidget = new OktaSignIn(this.signInWidgetConfig);
                        /* Region Google IDP */
                        this.signInWidget.on('ready', function () {
                            if (_this.showGoogleButton) {
                                var widgetFormEl = document.querySelector('form');
                                var widgetDividerEl = document.querySelector('.auth-divider');
                                var loginButton = document.querySelector('.social-auth-google-button');
                                var helpLinkEls = document.querySelectorAll('#help-links-container li');
                                if (widgetFormEl) {
                                    widgetFormEl.style.display = 'none';
                                    widgetFormEl.style.visibility = 'hidden';
                                }
                                if (widgetDividerEl) {
                                    widgetDividerEl.style.display = 'none';
                                    widgetDividerEl.style.visibility = 'hidden';
                                }
                                if (loginButton && !loginButton.hasAttribute('clickevent')) {
                                    loginButton.setAttribute('clickevent', '');
                                    loginButton.addEventListener('click', function () { _this.message = null; });
                                }
                                if (helpLinkEls.length > 0) {
                                    helpLinkEls.forEach(function (helpLinkEl) {
                                        var displayLink = _this.googleHelpLinks.includes(helpLinkEl.textContent);
                                        helpLinkEl.style.display = displayLink ? 'list-item' : 'none';
                                        helpLinkEl.style.visibility = displayLink ? 'visible' : 'hidden';
                                    });
                                }
                            }
                        });
                        /* End Region */
                        /* Region Okta Account Creation Error */
                        this.signInWidget.on('afterError', function (context, error) {
                            if (_this.showGoogleButton
                                && context.controller && context.controller === 'primary-auth'
                                && error.name && error.name === 'OAUTH_ERROR'
                                && error.message && error.message === 'User creation was disabled.') {
                                _this.message = 'Your Google account was not recognized.';
                            }
                        });
                        /* End Region */
                        this.signInWidget.renderEl({ el: '#widget' }, function (res) {
                            _this.onLoginResponse(res);
                        }, function (err) {
                            console.error(err);
                        });
                        // Handle message codes
                        this.route.queryParams.subscribe(function (params) {
                            if (params.code) {
                                switch (params.code) { // These codes are being kept in alignment with CartusOnline for consistency
                                    case '4': {
                                        _this.message = 'You have been logged out due to inactivity.';
                                        break;
                                    }
                                    case '5': {
                                        _this.message = 'Your account is no longer active.';
                                        _this.inactiveUser = true;
                                        break;
                                    }
                                    case '14': {
                                        _this.message = 'Your session has expired.';
                                        break;
                                    }
                                    default: {
                                        _this.message = null;
                                    }
                                }
                            }
                        });
                        // Set logo based on referrer
                        this.prefSub = this.userPreferencesService.getPreference('referrer', false).subscribe(function (val) {
                            _this.referredURL = val ? val.replace(/(\/#|\/|#)$/, '') : '';
                            var res = _this.cookieLogoSvc.setLogo(_this.referredURL);
                            if (res) {
                                _this.logoConfig = { logo: res, type: 'main' };
                            }
                        });
                        this.moveSharedService.loginUserDetails.subscribe(function (loginUserDetails) {
                            _this.userDetails = loginUserDetails;
                            if (!!_this.userDetails && !!_this.userDetails.userId && _this.userDetails.userId.length > 0) {
                                switch (_this.userDetails.product) {
                                    case 'Alpha': // Non-Springboard Product Name is still returned by the API
                                        _this.logoConfig = { logo: 'mobilifyHR', type: 'main' };
                                        break;
                                    case 'MovePro': { // Non-Springboard Product Name is still returned by the API
                                        if (_this.userDetails.systemOfOrigin === 'Traditional') {
                                            _this.logoConfig = { logo: 'cartus', type: 'main' };
                                        }
                                        else {
                                            _this.logoConfig = { logo: 'movePro360', type: 'main' };
                                        }
                                        break;
                                    }
                                    case 'BenefitsBuilder': // Non-Springboard Product Name is still returned by the API
                                        _this.logoConfig = { logo: 'benefitsBuilder', type: 'main' };
                                        break;
                                    case 'SupplierPortal': // Non-Springboard Product Name is still returned by the API
                                        _this.logoConfig = { logo: 'supplierPortal', type: 'login' };
                                        break;
                                    case 'CompensationServices': // Non-Springboard Product Name is still returned by the API
                                        _this.logoConfig = { logo: 'compensationServices', type: 'login' };
                                        break;
                                    default:
                                        break;
                                }
                            }
                        });
                        // SSO check
                        // No SSO in local mode
                        if (!this.appConfig.getConfig('local')) {
                            this.authSvc.trySso().then(function (tokenOrTokens) {
                                if (tokenOrTokens && tokenOrTokens.tokens && tokenOrTokens.tokens.accessToken) {
                                    // SSO authenticated
                                    _this.cookieLogoSvc.setSessionCookie(tokenOrTokens.tokens.accessToken);
                                    _this.subscription.add(_this.authSvc
                                        .resetPasswordAttempts({
                                        "Authorization": tokenOrTokens.tokens.accessToken.accessToken
                                    }).subscribe(function () {
                                        // SSO authenticated - navigate to springboard
                                        _this._ngZone.run(function () {
                                            _this.router.navigate(['/springboard']);
                                        });
                                    }));
                                }
                                else {
                                    _this.spinner.hide(); // Not SSO authenticated
                                }
                            });
                        }
                        else {
                            this.spinner.hide(); // No SSO Check
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
       * To get the GoogleIDP feature flag
       */
    LoginComponent.prototype.getGoogleIDPFeatureFlag = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.featureFlagService.getgoogleIDPFeatureFlag()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /** Component Angular destructor lifecycle hook */
    LoginComponent.prototype.ngOnDestroy = function () {
        if (this.signInWidget) {
            this.signInWidget.remove();
        }
        if (this.prefSub) {
            this.prefSub.unsubscribe();
        }
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.routeSub) {
            this.routeSub.unsubscribe();
        }
    };
    /** detect Edge Chromium for SP-341: Buttons and text indistingushable in High Contrast Mode */
    LoginComponent.prototype.detectEdgeChromium = function () {
        var agent = window.navigator.userAgent.toLowerCase();
        return agent.indexOf('edg') > -1 ? true : false;
    };
    LoginComponent.prototype.onLoginResponse = function (res) {
        var _this = this;
        if (res.status === 'FORGOT_PASSWORD_EMAIL_SENT') {
            // TBD
        }
        if (res.status === 'UNLOCK_ACCOUNT_EMAIL_SENT') {
            // TBD
        }
        if (res.status === 'PASSWORD_EXPIRED') { // Is this still needed?
            if (res.tokens.idToken.claims.email.toLowerCase().includes('cartus.com')) {
                this.router.navigate(['/updatePassword']);
            }
        }
        if (res.status === 'LOCKED_OUT') { // Is this still needed?
            if (res.tokens.idToken.claims.email.toLowerCase().includes('cartus.com')) {
                this.requestEmailStatus = true;
            }
        }
        if (res.status === 'SUCCESS') {
            // SSO Code for later - Do not remove
            // // OIDC
            // if (res.type === 'SESSION_STEP_UP') {
            //   console.log(res.user);
            //   console.log('Target resource url: ' + res.stepUp.url);
            // res.stepUp.finish();
            //   return;
            // } else {
            //   console.log(res.user);
            //   res.session.setCookieAndRedirect('https://acme.com/app');
            //   return;
            // }
            // // Not OIDC
            // console.log(res.claims);
            // this.signInWidget.tokenManager.add('my_id_token', res);
            // this.signInWidget.tokenManager.add('my_id_token', res[0]);
            // this.signInWidget.tokenManager.add('my_access_token', res[1]);
            // return;
            if (res && res.tokens && res.tokens.idToken && res.tokens.idToken.claims && res.tokens.idToken.claims.sub) {
                this.subscription.add(this.authSvc
                    .resetPasswordAttempts({
                    "Authorization": res.tokens.accessToken.accessToken
                })
                    .subscribe((function () {
                    _this.cookieLogoSvc.setSessionCookie(res.tokens.accessToken);
                    // Store the tokens in the token manager so it can signout and revoke
                    _this.authSvc.authClient.tokenManager.setTokens({ accessToken: res.tokens.accessToken,
                        idToken: res.tokens.idToken });
                    if (_this.userDetails) {
                        var urls = [];
                        if (_this.userDetails.product === 'Alpha') { // Non-Springboard Product Name is still returned by the API
                            if (_this.userDetails.roleName === 'candidate') {
                                urls = _this.appConfig.getConfig(_this.authorizedLocations[2]).toString().split('|');
                            }
                            else if (_this.userDetails.roleName === 'supplier-contact') {
                                urls = _this.appConfig.getConfig(_this.authorizedLocations[3]).toString().split('|');
                            }
                            else {
                                urls = _this.appConfig.getConfig(_this.authorizedLocations[1]).toString().split('|');
                            }
                        }
                        else if (_this.userDetails.product === 'MovePro') { // Non-Springboard Product Name is still returned by the API
                            if (_this.userDetails.systemOfOrigin === 'Traditional') {
                                urls = _this.appConfig.getConfig(_this.authorizedLocations[6]).toString().split('|');
                            }
                            else {
                                urls = _this.appConfig.getConfig(_this.authorizedLocations[0]).toString().split('|');
                            }
                        }
                        else if (_this.userDetails.product === 'BenefitsBuilder') { // Non-Springboard Product Name is still returned by the API
                            urls = _this.appConfig.getConfig(_this.authorizedLocations[4]).toString().split('|');
                        }
                        else if (_this.userDetails.product === 'CompensationServices') { // Non-Springboard Product Name is still returned by the API */
                            urls = _this.appConfig.getConfig(_this.authorizedLocations[5]).toString().split('|');
                        }
                        if (urls.length > 0) {
                            _this.selfRegLoginURL = urls[0];
                            _this.spinner.hide();
                            _this.router.navigate(['/externalRedirect', { externalUrl: _this.selfRegLoginURL }], {
                                skipLocationChange: true,
                            });
                        }
                        else { // Unrecognized userDetails
                            console.error('Unrecognized userDetails');
                        }
                    }
                    else { // No userDetails - navigate to springboard
                        _this._ngZone.run(function () {
                            _this.router.navigate(['/springboard']);
                        });
                    }
                })));
            }
        }
        this.spinner.hide();
    };
    return LoginComponent;
}());
export { LoginComponent };
